<template>
  <b-sidebar
    :id="id"
    ref="sidebar"
    :visible="isSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >

    <template #default="{ hide }">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="itemId || itemData.id"
            class="mb-0"
          > <span v-t="`modules.affiliates.pages.edit`" />: {{ itemData.name || itemId }}</h5>
          <h5
            v-else
            v-t="`modules.affiliates.pages.add`"
            class="mb-0"
          />

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Body -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div>
              <i-input
                id="firstName"
                v-model="itemData.name"
                :errors="validationErrors.name"
                type="text"
                title="inputs.name"
                required
              />
            </div>

            <div>
              <i-input
                id="email"
                v-model="itemData.email"
                :errors="validationErrors.email"
                type="email"
                title="inputs.email"
              />
            </div>

            <div>
              <i-input
                id="mobile"
                v-model="itemData.mobile"
                :errors="validationErrors.mobile"
                type="tel"
                title="inputs.mobile"
                :dial-code="itemData.mobile_code"
                @update:dial-code="val => itemData.mobile_code = val"
              />
            </div>

            <div>

              <i-input
                v-model="itemData.country_code"
                :errors="validationErrors.country_code"
                type="select"
                title="inputs.country"
                :options="countryOptions"
                :clearable="true"
                :reduce="c => c.id"
              />
            </div>
            <hr>

            <!-- commission_type -->
            <b-form-group class="mb-2">
              <label>
                {{ $t('modules.affiliates.inputs.commission_type') }}
              </label>
              <b-form-radio
                v-model="itemData.commission_type"
                class="mt-50 w-100"
                name="commission_type"
                value="fixed"
              >
                {{ $t('modules.affiliates.inputs.commission_type_options.fixed') }}
              </b-form-radio>
              <b-form-radio
                v-model="itemData.commission_type"
                class="mt-50 w-100"
                name="commission_type"
                value="percentage"
              >
                {{ $t('modules.affiliates.inputs.commission_type_options.percentage') }}
              </b-form-radio>
            </b-form-group>

            <!-- commission -->
            <validation-provider
              #default="{ errors }"
              rules="required|min:0"
              :name="$t('modules.affiliates.inputs.commission')"
            >
              <b-form-group class="mb-2">
                <label>
                  {{ $t('modules.affiliates.inputs.commission') }}
                </label>
                <b-input-group
                  :append="itemData.commission_type==='fixed' ? getLocaleCurrency() : '%'"
                >
                  <b-form-input
                    v-model="itemData.commission"
                    placeholder="50"
                    type="number"
                    min="0"
                    :max="itemData.commission_type==='fixed' ? 1000000 : 100"

                    :class="{'is-invalid' : isHasValidationError('commission', errors)}"
                    :aria-invalid="isHasValidationError('commission', errors)"
                  />
                </b-input-group>
              </b-form-group>
            </validation-provider>

            <!-- commission_percentage_base -->
            <Transition name="fade-bottom">
              <b-form-group
                v-if="itemData.commission_type === 'percentage'"
                class="mb-2"
              >
                <label>
                  {{ $t('modules.affiliates.inputs.commission_percentage_base') }}
                </label>
                <b-form-radio
                  v-model="itemData.commission_percentage_base"
                  class="mt-50 w-100"
                  name="commission_percentage_base"
                  value="discounted_subtotal"
                >
                  {{ $t('modules.affiliates.inputs.commission_percentage_base_options.discounted_subtotal') }}
                </b-form-radio>
                <b-form-radio
                  v-model="itemData.commission_percentage_base"
                  class="mt-50 w-100"
                  name="commission_percentage_base"
                  value="order_total"
                >
                  {{ $t('modules.affiliates.inputs.commission_percentage_base_options.order_total') }}
                </b-form-radio>
              </b-form-group>
            </Transition>

            <!-- is_commission_on_order_delivered -->
            <b-form-group class="mb-2">
              <label>
                {{ $t('modules.affiliates.inputs.is_commission_on_order_delivered') }}
              </label>
              <b-form-radio
                v-model="itemData.is_commission_on_order_delivered"
                class="mt-50 w-100"
                name="is_commission_on_order_delivered"
                :value="false"
              >
                {{ $t('modules.affiliates.inputs.is_commission_on_order_delivered_options.0') }}
              </b-form-radio>
              <b-form-radio
                v-model="itemData.is_commission_on_order_delivered"
                class="mt-50 w-100"
                name="is_commission_on_order_delivered"
                :value="true"
              >
                {{ $t('modules.affiliates.inputs.is_commission_on_order_delivered_options.1') }}
              </b-form-radio>
            </b-form-group>

            <!-- is_commission_on_all_orders -->
            <!--            <b-form-group class="mb-2">-->
            <!--              <label>-->
            <!--                {{ $t('modules.affiliates.inputs.is_commission_on_all_orders') }}-->
            <!--              </label>-->
            <!--              <b-form-radio-->
            <!--                v-model="itemData.is_commission_on_all_orders"-->
            <!--                class="mt-50 w-100"-->
            <!--                name="is_commission_on_all_orders"-->
            <!--                :value="false"-->
            <!--              >-->
            <!--                {{ $t('modules.affiliates.inputs.is_commission_on_all_orders_options.0') }}-->
            <!--              </b-form-radio>-->
            <!--              <b-form-radio-->
            <!--                v-model="itemData.is_commission_on_all_orders"-->
            <!--                class="mt-50 w-100"-->
            <!--                name="is_commission_on_all_orders"-->
            <!--                :value="true"-->
            <!--              >-->
            <!--                {{ $t('modules.affiliates.inputs.is_commission_on_all_orders_options.1') }}-->
            <!--              </b-form-radio>-->
            <!--            </b-form-group>-->

            <div>
              <i-input
                v-model="itemData.is_active"
                :errors="validationErrors.is_active"
                type="switch"
                title="inputs.is_active"
                description="inputs.is_active:description"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </i-input>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-t="(itemId || itemData.id)?`Edit`:`Add`"
                variant="primary"
                class="mr-2"
                type="submit"
              />
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>

      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BOverlay, BFormRadio, BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import store from '@/store'
import affiliateStoreModule from '@/views/models/affiliates/affiliateStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { getLocaleCurrency } from '@core/utils/utils'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormRadio,
    ValidationObserver,
    BSidebar,
    BForm,
    BButton,
    BOverlay,
  },
  directives: {
  },
  emits: ['refetch-data'],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: 'sidebar-add-new-category',
      required: false,
    },
    itemId: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
  },
  watch: {
    itemId(newValue) {
      if (newValue) {
        this.isLoading = true
        this.fetchItem(newValue).finally(() => {
          this.isLoading = false
        })
      } else {
        this.resetForm()
      }
    },
    isSidebarActive(newValue) {
      if (newValue) {
        // if (!this.itemId) {
        this.resetForm()
        // }
      } else {
        this.resetForm()
      }
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: { getLocaleCurrency },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'affiliates'

    // Register module
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, affiliateStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const emptyItemData = {
      id: null,
      name: null,
      email: null,
      mobile_code: null,
      mobile: null,
      country_code: null,
      // Configs
      commission_type: 'fixed',
      commission: null,
      commission_percentage_base: 'discounted_subtotal',
      is_commission_on_order_delivered: false,
      // is_commission_on_all_orders: false,

      is_active: true,
    }
    const itemData = ref({})
    const isLoading = ref(true)

    const countryOptions = ref([])

    store.dispatch('affiliates/fetchCountries')
      .then(response => { countryOptions.value = response.data.data })

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(props.value || emptyItemData))
      isLoading.value = false
      ctx.emit('update:item-id', null)
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const { validationErrors, fetch, save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        ctx.emit('refetch-data')
        ctx.emit('update:is-sidebar-active', false)
      },
      null,
      null,
      () => {
        resetItemData()

        ctx.emit('update:is-sidebar-active', false)
      },
    )

    const fetchItem = () => {
      if (itemData.value.id !== props.itemId) {
        itemData.value.id = props.itemId

        isLoading.value = true
        return fetch().finally(() => {
          isLoading.value = false
        })
      }
      return null
    }

    const onSubmit = () => {
      isLoading.value = true
      save(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    const isHasValidationError = (attr, errors) => (errors.length > 0) || (validationErrors.value[attr] && validationErrors.value[attr].length > 0)

    return {
      itemData,
      fetchItem,
      validationErrors,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetItemData,
      resetForm,
      isHasValidationError,

      refs,
      isLoading,
      countryOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vti__dropdown-list {
  z-index: 2;
}
</style>
